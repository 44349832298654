import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeInUpAnimation } from 'src/@omnial/_animations/fade-in-up.animation';
import { Topic } from 'src/@omnial/_models/catalog/topic.model';
import { TopicService } from 'src/@omnial/_services/catalog/topic.service';

@Component({
    selector: 'app-topic',
    templateUrl: './../../../app/pages/topic/topic.component.html',
    styleUrls: ['./../../../app/pages/topic/topic.component.scss'],
    animations: [fadeInUpAnimation],
    standalone: false
})

export class TopicComponent implements OnInit {
  @Input() topicSlug: string;
  public currentTopic: Topic;
  public subscriptions: Subscription[] = [];

  constructor(public topicService: TopicService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  ngOnInit(): void {
    if (!this.currentTopic && this.topicSlug) {
      this.topicService.getTopicBySlug(this.topicSlug).subscribe(
        res => {
          if (res) {
            this.currentTopic = res as Topic;
          }
        });
    }
  }
}
